import { Box, generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('NavbarWithContact', [
  'imageName',
]);

export const StyledImagePlaceholder = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '90%',
  color: theme.palette.common.white,
  height: 48,
  width: 48,
  [`& .${classes.imageName}`]: {
    display: 'grid',
    height: '100%',
    placeContent: 'center',
    width: '100%',
  },
}));
