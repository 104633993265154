import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { useEffect } from 'react';

export function useFontAwesomeIconsEffect(icons: IconDefinition[]) {
  useEffect(() => {
    library.add(...icons);

    return () => library.reset();
  }, [icons]);
}
