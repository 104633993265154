import { styled } from '@mui/material/styles';

import { Button } from '@grimme/components';

export const StyledButton = styled(Button)({
  position: 'fixed',
  top: 13.5,
  left: 0,
  zIndex: 9999999,
});

export const PreviewModeButton = () => {
  return (
    <StyledButton href="/api/exit-preview" variant="primary">
      Exit Preview Mode
    </StyledButton>
  );
};
