import axios from 'axios';
import { environment } from '@/environment';
import { IUser } from '@/hooks';

export const updateProfileLanguage = (
  accessToken: string,
  language: string,
  profile: IUser,
) => {
  const shouldUpdateLanguage =
    language && profile && language !== profile.preferredLanguageCode;

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  if (shouldUpdateLanguage) {
    return axios.patch<IUser>(
      `${environment.gridApi}/api/v1/user/me`,
      {
        preferredLanguageCode: language,
      },
      {
        headers,
      },
    );
  }
  return undefined;
};
